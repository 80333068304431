<template>
  <div class="flex-grow-1 w-100 d-flex align-items-center">
    <div class="bg-composed-wrapper--content py-5">
      <div class="container">
        <div class="row">
          <div
            class="
              col-lg-8
              pl-0
              flex-column
              align-items-start
              justify-content-center
              text-center text-lg-left
              signin-title--container
              pr-5
            "
          >
            <h5 class="display-3 mt-1 mb-2 font-weight-bold">
              ยินดีต้อนรับเข้าสู่เว็บไซต์
            </h5>

            <h3 class="display-1 mt-1 mb-2 text-first font-weight-bold">
              E-Learning D.A.R.E. ประเทศไทย
            </h3>

            <h5 class="display-3 mt-1 mb-2 font-weight-bold">
              สำหรับ Admin
            </h5>

            <img
              src="@/assets/img/login-new.jpg"
              class="img-fluid d-none d-lg-flex"
            />
            <!-- <LoginSvg class="mx-auto d-block img-fluid" /> -->
          </div>

          <div
            class="
              col-10
              offset-1
              col-lg-4
              offset-lg-0
              pr-0
              d-flex
              flex-column
              justify-content-center
            "
          >
            <b-card class="text-black mt-3 my-4">
              <LogoSvg class="w-50 mx-auto d-block img-fluid mb-3" />

              <h1 class="display-3 mb-3 text-primary font-weight-bold">
                เข้าสู่ระบบ
              </h1>
              <!-- 
              <div class="bg-secondary rounded p-4 my-4">
                <div class="text-black-50 text-center mb-3">
                  Sign in with
                </div>
                <div class="row">
                  <div class="col">
                    <button
                      class="btn btn-facebook btn-block"
                      type="button"
                      v-b-tooltip.hover
                      title="Facebook"
                    >
                      <span class="btn-wrapper--icon">
                        <font-awesome-icon :icon="['fab', 'facebook']" />
                      </span>
                    </button>
                  </div>
                  <div class="col">
                    <button
                      class="btn btn-twitter btn-block"
                      type="button"
                      v-b-tooltip.hover
                      title="Twitter"
                    >
                      <span class="btn-wrapper--icon">
                        <font-awesome-icon :icon="['fab', 'twitter']" />
                      </span>
                    </button>
                  </div>
                </div>
              </div> -->

              <div>
                <form>
                  <div class="form-group">
                    <label for="tel">เบอร์โทรศัพท์</label>
                    <input
                      type="tel"
                      class="form-control"
                      name="tel"
                      placeholder="0xx-xxx-xxxx"
                      v-mask="'0##-###-####'"
                      v-model="form.tel"
                    />
                  </div>

                  <button
                    class="btn btn-lg btn-primary btn-block"
                    :disabled="checkingSignIn || otpTimeout > 0"
                    @click.prevent="signIn"
                  >
                    <b-spinner
                      v-if="checkingSignIn"
                      label="Loading..."
                    ></b-spinner>
                    <span v-else>
                      {{
                        otpTimeout > 0
                          ? `กรุณารออีก ${otpTimeout} วินาที เพื่อขอ OTP เข้าสู่ระบบใหม่อีกครั้ง`
                          : "เข้าสู่ระบบ"
                      }}
                    </span>
                  </button>
                </form>
              </div>
            </b-card>

            <b-modal
              v-model="showModalOTP"
              id="otp-modal"
              centered
              hide-footer
              hide-header
            >
              <!-- <div slot="modal-header">
                <h6 class="display-4 font-weight-bold">Search</h6>
                <p class="text-black-50 mb-0">
                  Use the form below to search for files
                </p>
              </div> -->
              <b-container>
                <b-row>
                  <b-col>
                    <h5 class="display-3 text-primary font-weight-bold">
                      กรอกรหัส OTP
                    </h5>

                    <p class="text-black-50">
                      รหัส OTP ได้ถูกส่งไปยังเบอร์โทรศัพท์หมายเลข {{ form.tel }}
                    </p>

                    <form>
                      <div class="form-group">
                        <label for="otp_code">รหัส OTP</label>
                        <input
                          type="text"
                          class="form-control"
                          name="otp_code"
                          v-model="form.otp_code"
                        />
                      </div>

                      <button
                        class="btn btn-lg btn-primary btn-block"
                        :disabled="checkingOTP"
                        @click.prevent="validateOTP"
                      >
                        <b-spinner
                          v-if="checkingOTP"
                          label="Loading..."
                        ></b-spinner>
                        <span v-else>ยืนยัน</span>
                      </button>
                    </form>
                  </b-col>
                </b-row>
              </b-container>
            </b-modal>

            <!-- <button
              class="btn btn-lg btn-danger btn-block"
              @click="showModalDoc = true"
            >
              ดูคู่มือการใช้งาน D.A.R.E.
            </button>

            <router-link
              class="mt-3"
              active-class="active"
              to="/registers"
              exact
            >
              <b-button variant="outline-danger" block>
                สมัครสมาชิกชมรม D.A.R.E. ประเทศไทย
              </b-button>
            </router-link> -->

            <!-- <b-button
              variant="outline-danger"
              target="_blank"
              href="https://d10a8qjc50njp0.cloudfront.net/misc/%E0%B9%83%E0%B8%9A%E0%B8%AA%E0%B8%A1%E0%B8%B1%E0%B8%84%E0%B8%A3%E0%B8%AA%E0%B8%A1%E0%B8%B2%E0%B8%8A%E0%B8%B4%E0%B8%81%E0%B8%8A%E0%B8%A1%E0%B8%A3%E0%B8%A1+D.A.R.E.+%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8%E0%B9%84%E0%B8%97%E0%B8%A2.pdf"
              class="mt-3"
            >
              ใบสมัครสมาชิกชมรม D.A.R.E. ประเทศไทย
            </b-button> -->

            <b-row class="mt-3 text-center">
              <b-col>
                แจ้งปัญหา/ขอความช่วยเหลือเกี่ยวกับระบบได้ที่
                <a target="_blank" href="http://line.me/ti/p/@858xnebk"
                  >http://line.me/ti/p/@858xnebk</a
                >
              </b-col>
            </b-row>

            <b-modal
              v-model="showModalDoc"
              id="doc-modal"
              size="xl"
              centered
              hide-footer
              hide-header
            >
              <!-- <div slot="modal-header">
                <h6 class="display-4 font-weight-bold">Search</h6>
                <p class="text-black-50 mb-0">
                  Use the form below to search for files
                </p>
              </div> -->
              <b-container>
                <b-row class="flex-sm-column-reverse flex-md-row">
                  <b-col
                    lg="5"
                    class="d-flex flex-column justify-content-between"
                  >
                    <h5
                      class="
                        display-3
                        mt-sm-4 mt-md-2
                        mb-3
                        text-primary
                        font-weight-bold
                      "
                    >
                      คู่มือการใช้งานระบบฐานข้อมูล D.A.R.E.
                    </h5>

                    <div>
                      <h5 class="display-5 mb-2 text-primary font-weight-bold">
                        ดาวน์โหลดเอกสาร
                      </h5>

                      <div>
                        <u>
                          <a
                            href="https://drive.google.com/file/d/1dN0fQpS5XgoTFVNIP-9_lswylqEut-4Z/view"
                            target="_blank"
                            >ไฟล์เอกสาร PDF</a
                          >
                        </u>
                      </div>

                      <!-- <div>
                        <u>
                          <a href="http://" target="_blank"
                            >ไฟล์เอกสาร PowerPoint</a
                          >
                        </u>
                      </div> -->
                    </div>
                  </b-col>
                  <b-col lg="7" class="mt-md-2">
                    <div class="video-container">
                      <!-- <iframe
                        width="1537"
                        height="872"
                        src="https://www.youtube.com/embed/RmuL-BPFi2Q?list=RDRmuL-BPFi2Q"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe> -->
                      <b-embed
                        src="https://www.youtube.com/embed/vmd2KaTElDE"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      >
                      </b-embed>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoSvg from "../../assets/img/logo.svg";
import { Auth } from "../../models";

export default {
  components: {
    LogoSvg,
  },

  data() {
    return {
      showModalDoc: false,
      showModalOTP: false,
      checkingSignIn: false,
      checkingOTP: false,
      otpTimeout: 0,
      optInterval: null,
      form: {
        tel: "",
        authToken: "",
        token: "",
        user_profiles: null,
        otp_code: "",
      },
    };
  },

  watch: {
    otpTimeout: "onOtpTimeoutChanged",
  },

  methods: {
    async signIn() {
      this.checkingSignIn = true;

      let { tel } = this.form;

      tel = tel.replace(/-/g, "");

      try {
        const { user_profiles, token: authToken } = await Auth.api().signIn(
          tel
        );

        this.$set(this.form, "authToken", authToken);
        this.$set(this.form, "user_profiles", user_profiles);

        const token = await Auth.api().getOTP(tel);

        this.$set(this.form, "token", token);

        this.setOtpTimeout();

        this.showModalOTP = true;
      } catch (message) {
        this.$toast.error(message);
      } finally {
        this.checkingSignIn = false;
      }
    },

    setOtpTimeout() {
      this.otpTimeout = 30;
    },

    onOtpTimeoutChanged(v) {
      if (v > 0 && !this.optInterval) {
        this.optInterval = setInterval(() => {
          this.otpTimeout -= 1;
        }, 1000);
      } else {
        if (v === 0) {
          clearInterval(this.optInterval);
          this.optInterval = null;
        }
      }
    },

    async validateOTP() {
      this.checkingOTP = true;
      try {
        if (!this.form.otp_code.length) {
          throw "กรุณากรอกรหัส OTP";
        }

        const valid = await Auth.api().validateOTP(
          this.form.token,
          this.form.otp_code
        );

        if (valid) {
          Auth.setAuthToken(this.form.authToken);
          Auth.setAuthId(this.form.user_profiles.id);

          this.showModalOTP = false;

          this.$toast.success(
            "เข้าสู่ระบบสำเร็จ ระบบจะเข้าสู้หน้าหลักในอีกสักครู่"
          );

          setTimeout(() => {
            location.href =
              decodeURIComponent(this.$route.query.redirectUrl) || "/";
          }, 1000);
        }
      } catch (message) {
        console.log(message);
        this.$toast.error(message);
      } finally {
        this.checkingOTP = false;
      }
    },
  },
};
</script>
